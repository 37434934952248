<template>
  <div>
    <div class="mt-1">
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="8"
        >
          <b-card no-body>
            <div class="pt-2 pl-3">
              <b-form-group>
                <b-form-radio-group
                  v-model="currentStatus"
                  :options="statusOptions"
                  switches
                />
              </b-form-group>
            </div>
          </b-card>
        </b-col>
        <b-col
          sm="12"
          md="12"
          lg="4"
        >
          <div class="card">
            <b-input-group
              class="input-group-merge p-1 mt-0"
              size="sm"
            >
              <b-form-input
                v-model="searchValue"
                size="sm"
                placeholder="Entrer pour rechercher"
              />
              <b-input-group-append is-text>
                <span
                  class="cursor"
                  @click="getUser"
                >
                  <feather-icon icon="SearchIcon" />
                </span>
              </b-input-group-append>
              <b-input-group-append is-text>
                <span
                  class="cursor"
                  @click="clearSearch"
                >
                  <feather-icon icon="XIcon" />
                </span>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
      </b-row>
      <div
        v-if="loadSearch"
        class="text-center"
      >
        <b-spinner variant="primary" />
        <div class="d-flex justify-content-center">
          <p>Recherche en cours</p>
        </div>
      </div>
      <div
        v-if="loaded"
        class="text-center"
      >
        <b-spinner variant="primary" />
      </div>
      <div v-if="!loaded && items.length > 0">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="mb-2"
        >
          <b-card
            :class="{'d-flex flex-row':true}"
            no-body
          >
            <div class="d-flex flex-grow-1 min-width-zero">
              <div class="card-body p-1 d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                <p class="mb-0 font-weight-bold text-primary w-30 w-sm-100">
                  {{ item.name + ' ' + item.surname }}
                </p>
                <p class="mb-0 text-small w-10 w-sm-100">
                  {{ item.sex ? item.sex : 'Non fourni' }}
                </p>
                <p class="mb-0 text-small w-10 w-sm-100">
                  {{ item.birthDate ? item.birthDate : 'Non fourni' }}
                </p>
                <p class="mb-0 text-small w-20 w-sm-100">
                  {{ item.address + ' / ' + item.city + ' / ' + item.country }}
                </p>
                <p class="mb-0 text-small w-10 w-sm-100">
                  <b-badge
                    :variant="item.account_locked === 'DISABLED' ? 'primary' : 'danger'"
                    pill
                  >
                    {{ item.account_locked === 'DISABLED' ? 'Actif' : 'Inactif' }}
                  </b-badge>
                </p>
              </div>
              <div
                class="custom-control custom-checkbox pl-1 pt-1 align-self-center pr-1"
              >
                <div class="mb-1 text-small w-5 w-sm-100">
                  <b-dropdown
                    variant="link"
                    dropright
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template #button-content>
                      <b-icon
                        class="text-primary"
                        icon="three-dots-vertical"
                        font-scale="1.2"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="currentStatus !== 'CREATED'"
                      @click="openSubmitModal(item)"
                    >
                      <b-icon
                        class="ml-1 text-info"
                        icon="eye"
                        font-scale="1.2"
                      />
                      <span class="ml-1">Documents soumis</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="openLockModal(item)">
                      <b-icon
                        class="ml-1 text-danger"
                        :icon="`${item.account_locked === 'DISABLED' ? 'lock' : 'unlock'}`"
                        font-scale="1.2"
                      />
                      <span
                        v-if="item.account_locked === 'DISABLED'"
                        class="ml-1"
                      >
                        Bloquer
                      </span>
                      <span
                        v-else
                        class="ml-1"
                      >
                        Débloquer
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="checkUserHistory(item.id)">
                      <b-icon
                        class="ml-1 text-info"
                        icon="bar-chart"
                        font-scale="1.2"
                      />
                      Voir les transactions
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
          </b-card>
        </div>
      </div>
      <div v-if="!loaded && items.length === 0">
        <b-card
          :class="{'d-flex flex-row':true}"
          no-body
        >
          <div class="pl-2 d-flex flex-grow-1 min-width-zero">
            <div class="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
              <div class="text-center">
                <p class="mb-0 font-weight-bold text-primary w-100 w-sm-100">
                  <span v-if="currentStatus === 'NEW_DOCUMENT'">Aucun profil en attente..</span>
                  <span v-if="currentStatus === 'PENDING'">Aucun profil en traitement.</span>
                  <span v-if="currentStatus === 'WAITING'">Aucun profil en attente de validation.</span>
                  <span v-if="currentStatus === 'VALID'">Aucun profil validé.</span>
                  <span v-if="currentStatus === 'REJECTED'">Aucun profil rejeté.</span>
                  <span v-if="!currentStatus">Aucun profil trouvé.</span>
                </p>
              </div>
            </div>
          </div>
        </b-card>
      </div>
      <div
        v-if="!loaded && items.length > 0"
        class="mt-2"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
          align="center"
        />
      </div>
    </div>

    <b-modal
      v-if="showModal"
      v-model="showModal"
      title="Documents"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      size="lg"
    >
      <b-tabs justified>
        <b-tab title="Fichiers">
          <b-table
            :items="currentUserDocuments"
            :fields="fields"
            responsive
            hover
            show-empty
            empty-text="Aucun document"
            :busy="loadedFiles"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>
            <template v-slot:cell(action)="data">
              <b-dropdown
                class="m-0 p-0"
                variant="link"
                text="Voir"
                no-caret
                dropright
              >
                <span class="dropdown-item">
                  <a
                    :href="data.item.doc_uri"
                    target="_blank"
                  >
                    <b-icon
                      class="mt-1 mr-1 text-primary"
                      icon="eye"
                      font-scale="1.1"
                    />
                    Document
                  </a>
                </span>
                <b-dropdown-item
                  v-if="data.item.state === 'SUBMITTED'"
                  @click="openDocumentModal(data.item, 0)"
                >
                  <span class="cursor">
                    <b-icon
                      class="mt-1 mr-1 text-warning"
                      icon="arrow-bar-up"
                      font-scale="1.1"
                    />
                    <span class="">Traitement</span>
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.state === 'TREATMENT'"
                  @click="openDocumentModal(data.item, 1)"
                >
                  <b-icon
                    class="ml-1 mt-1 mr-1 text-success"
                    icon="check2-square"
                    font-scale="1.1"
                  />
                  <span class="mt-0">Valider</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.state === 'TREATMENT'"
                  @click="openDocumentModal(data.item, 2)"
                >
                  <b-icon
                    class="ml-1 mt-1 mr-1 text-danger"
                    icon="trash"
                    font-scale="1.1"
                  />
                  <span class="pt-0">Rejeter</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-slot:cell(type)="data">
              <span>{{ getDocumentName(data.item.doc_type) }}</span>
            </template>
            <template v-slot:cell(date)="data">
              <span>{{ data.item.created_at | moment("DD/MM/YY [à] HH:mm") }}</span>
            </template>
            <template v-slot:cell(state)="data">
              <b-badge
                pill
                :variant="getVariant(data.item.state)"
              >
                {{ getName(data.item.state) }}
              </b-badge>
            </template>
          </b-table>
          <hr class="my-3 bg-primary">
          <div class="mt-3">
            <div
              v-if="profileProcess"
              class="d-flex justify-content-center mb-2"
            >
              <b-spinner
                variant="primary"
                label="Loading..."
              />
            </div>
            <div
              class="float-right"
            >
              <b-button
                v-if="currentStatus === 'WAITING'"
                variant="outline-primary"
                @click="tryToValidProfile"
              >
                Valider le profil
              </b-button>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
    <b-modal
      v-if="showCurrentDocument"
      v-model="showCurrentDocument"
      :title="`${description}`"
      centered
      :busy="treatProcess || validProcess || rejectProcess"
      :no-close-on-esc="treatProcess || validProcess || rejectProcess"
      :hide-header-close="treatProcess || validProcess || rejectProcess"
      :no-close-on-backdrop="treatProcess || validProcess || rejectProcess"
      hide-footer
    >
      <div
        v-if="action === 0"
        class="text-justify"
      >
        Mise en traitement du document.
      </div>
      <div
        v-if="action === 1"
        class="text-justify"
      >
        Validation du document.
      </div>
      <b-form-group
        v-if="action === 2"
        label="Motif de rejet"
      >
        <b-form-textarea
          v-model="$v.motifRejet.$model"
          :class="{'is-invalid': $v.motifRejet.$error}"
        />
      </b-form-group>
      <hr class="my-3 bg-primary">
      <div class="mt-3">
        <div
          v-if="treatProcess || validProcess || rejectProcess"
          class="d-flex justify-content-center"
        >
          <b-spinner
            variant="primary"
            label="Loading..."
          />
        </div>
        <div
          v-if="currentDocumentStorage.rejectMotif"
          class="text-justify mt-2"
          h5
        >
          <p class="text-uppercase">
            Motif de rejet
          </p>
          {{ currentDocumentStorage.rejectMotif }}
        </div>
        <div>
          <div class="float-right">
            <b-button
              v-if="action === 0"
              size="xs"
              variant="outline-warning"
              @click="tryToTreat()"
            >
              Mettre en traitement
            </b-button>
          </div>
          <div>
            <div class="float-right">
              <b-button
                v-if="action === 2"
                size="xs"
                variant="outline-danger"
                @click="tryToReject()"
              >
                Rejeter
              </b-button>
            </div>
            <div class="float-right">
              <b-button
                v-if="action === 1"
                size="xs"
                variant="outline-success"
                @click="tryToValid()"
              >
                Valider
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-if="showLockModal"
      v-model="showLockModal"
      centered
      :title="`${currentUser.account_locked === 'DISABLED' ? 'Bloquer' : 'Débloquer'} un utilisateur`"
      :busy="lockProcess"
      :no-close-on-esc="lockProcess"
      :hide-header-close="lockProcess"
      :no-close-on-backdrop="lockProcess"
      hide-footer
    >
      <b-row>
        <b-col sm="12">
          <b-table-simple
            responsive
            hover
          >
            <b-tr>
              <th>
                Nom
              </th>
              <b-td>
                {{ currentUser.name + ' ' + currentUser.surname }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>
                Sexe
              </b-td>
              <b-td>
                {{ currentUser.sex ? currentUser.sex : 'Non fourni' }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>
                Localisation
              </b-td>
              <b-td>
                {{ currentUser.country.toUpperCase() + ' / ' + currentUser.city + ' / ' + currentUser.address }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>
                Email
              </b-td>
              <b-td>
                {{ currentUser.email }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>
                Téléphone
              </b-td>
              <b-td>
                {{ currentUser.phone }}
              </b-td>
            </b-tr>
          </b-table-simple>
        </b-col>
      </b-row>
      <div class="text-justify">
        <span v-if="currentUser.account_locked === 'DISABLED'">
          Vous êtes sur le point de bloquer cet utulisateur. Voulez-vous continuer ?
        </span>
        <span v-else>
          Vous êtes sur le point de débloquer cet utulisateur. Voulez-vous continuer ?
        </span>
      </div>
      <hr class="my-3 bg-primary">
      <div class="mt-1">
        <div
          v-if="lockProcess"
          class="d-flex justify-content-center"
        >
          <b-spinner
            variant="primary"
            label="Loading..."
          />
        </div>
        <div>
          <div class="float-right">
            <b-button
              size="xs"
              variant="outline-danger"
              @click="tryToBlock()"
            >
              Oui je continue
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { required } = require('vuelidate/lib/validators')

export default {
  data() {
    return {
      action: null,
      loaded: false,
      loadSearch: false,
      searchValue: null,
      loadedFiles: false,
      showModal: false,
      description: null,
      items: [],
      motifRejet: null,
      currentUserDocuments: [],
      currentSearchUser: null,
      fields: [
        {
          key: 'doc_type',
          label: 'Document',
          formatter: val => this.getDocumentName(val),
        },
        { key: 'date', label: 'Date' },
        { key: 'state', label: 'Etat' },
        { key: 'action', label: 'Action' },
      ],
      totalDocuments: 0,
      totalItems: 0,
      currentPage: 1,
      perPage: 10,
      showLockModal: false,
      currentStatus: 'NEW_DOCUMENT',
      statusOptions: [
        { value: 'CREATED', text: 'CREER' },
        { value: 'NEW_DOCUMENT', text: 'EN COURS' },
        { value: 'PENDING', text: 'EN TRAITEMENT' },
        { value: 'WAITING', text: 'EN VALIDATION' },
        { value: 'VALID', text: 'VALIDE' },
      ],
      showCurrentDocument: false,
      currentDocumentStorage: null,
      currentUser: null,
    }
  },
  validations: {
    motifRejet: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      profileProcess: 'validProfileProcess',
      profileSuccess: 'validProfileSuccess',
      profileError: 'validProfileError',

      validProcess: 'validKycDocumentProcess',
      validSuccess: 'validKycDocumentSuccess',
      validError: 'validKycDocumentError',

      rejectProcess: 'rejectKycDocumentProcess',
      rejectSuccess: 'rejectKycDocumentSuccess',
      rejectError: 'rejectKycDocumentError',

      treatProcess: 'treatKycDocumentProcess',
      treatSuccess: 'treatKycDocumentSuccess',
      treatError: 'treatKycDocumentError',

      lockProcess: 'lockAccountProcess',
      lockSuccess: 'lockAccountSuccess',
      lockError: 'lockAccountError',
    }),
  },
  watch: {
    currentPage() {
      this.getAllDocuments()
    },
    currentStatus() {
      this.getAllDocuments()
    },
    lockSuccess(val) {
      if (val) {
        this.closeLockModal()
        this.getAllDocuments()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Bloquage de compte',
            icon: 'CheckCircleIcon',
            text: 'Compte bloqué avec succès.',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    lockError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Bloquage de compte',
            icon: 'XCircleIcon',
            text: 'Une erreur est survenue lors du blocage.',
            variant: 'danger',
          },
        })
      }
    },
    treatSuccess(val) {
      if (val) {
        this.getAllDocumentsByPartner(this.currentUser.id)
        this.closeDocumentModal()
        this.getAllDocuments()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Mise en traitement',
            icon: 'CheckCircleIcon',
            text: 'Document mis en traitement avec succès.',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    treatError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Mise en traitement',
            icon: 'XCircleIcon',
            text: 'Une erreur est survenue lors de la mise en traitement.',
            variant: 'danger',
          },
        })
      }
    },
    rejectSuccess(val) {
      if (val) {
        this.getAllDocumentsByPartner(this.currentUser.id)
        this.closeDocumentModal()
        this.getAllDocuments()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Rejet de document',
            icon: 'CheckCircleIcon',
            text: 'Document rejeté avec succès.',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    rejectError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Rejet de document',
            icon: 'XCircleIcon',
            text: 'Une erreur est survenue lors du rejet.',
            variant: 'danger',
          },
        })
      }
    },
    validSuccess(val) {
      if (val) {
        this.getAllDocumentsByPartner(this.currentUser.id)
        this.closeDocumentModal()
        this.getAllDocuments()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Validation de document',
            icon: 'CheckCircleIcon',
            text: 'Document validé avec succès.',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    validError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Validation de document',
            icon: 'XCircleIcon',
            text: 'Une erreur est survenue lors de la validation.',
            variant: 'danger',
          },
        })
      }
    },
    profileSuccess(val) {
      if (val) {
        this.closeDocumentModal()
        this.closeSubmitModal()
        this.currentStatus = 'VALID'
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Validation de profil',
            icon: 'CheckCircleIcon',
            text: 'Profil validé avec succès.',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    profileError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Validation de profil',
            icon: 'XCircleIcon',
            text: 'Une erreur est survenue lors de la validation.',
            variant: 'danger',
          },
        })
      }
    },
  },
  mounted() {
    this.getAllDocuments()
  },
  methods: {
    ...mapActions(['validKycDocument', 'rejectKycDocument', 'treatKycDocument', 'validProfile', 'lockAccount']),
    checkUserHistory(item) {
      this.$router.push(`/admin/settings/user-transaction-history/${item}`)
    },
    async getUser() {
      this.loadSearch = true
      this.currentStatus = null
      this.items = []
      const data = JSON.stringify({
        term: this.searchValue,
      })
      this.items = await this.$http
        .post(`/user-search/${this.perPage}?page=${this.currentPage}`, data, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          this.loadSearch = false
          this.totalItems = res.data.meta.total
          return res.data.data
        })
        .catch(() => {
          this.loadSearch = false
          return []
        })
    },
    clearSearch() {
      this.searchValue = null
      if (this.currentStatus) return
      this.currentStatus = 'CREATED'
    },
    openSubmitModal(item) {
      this.currentUser = item
      this.getAllDocumentsByPartner(item.id)
      this.showModal = true
    },
    closeSubmitModal() {
      this.showModal = false
    },
    openLockModal(item) {
      this.currentUser = item
      this.showLockModal = true
    },
    closeLockModal() {
      this.showLockModal = false
    },
    openDocumentModal(item, action) {
      this.action = action
      this.currentDocumentStorage = item
      this.getDocumentName(item.doc_type)
      this.showCurrentDocument = true
    },
    closeDocumentModal() {
      this.showCurrentDocument = false
    },
    getVariant(item) {
      let name = null
      switch (item) {
        case 'SUBMITTED':
          name = 'primary'
          break
        case 'TREATMENT':
          name = 'warning'
          break
        case 'VALID':
          name = 'success'
          break
        case 'REJECTED':
          name = 'danger'
          break
        default:
          break
      }
      return name
    },
    getName(item) {
      let name = null
      switch (item) {
        case 'SUBMITTED':
          name = 'SOUMIS'
          break
        case 'TREATMENT':
          name = 'TRAITEMENT'
          break
        case 'VALID':
          name = 'VALIDE'
          break
        case 'REJECTED':
          name = 'REJETE'
          break
        default:
          break
      }
      return name
    },
    getDocumentName(item) {
      let name = null
      switch (item) {
        case 'ID':
          name = "PIECE D'IDENTITE"
          break
        case 'FACE_ID':
          name = "PIECE D'IDENTITE + SELFIE"
          break
        default:
          break
      }
      this.description = name
      return name
    },
    async getAllDocuments() {
      this.loaded = true
      this.items = await this.$http
        .get(`/user-by-state/${this.currentStatus}/${this.perPage}?page=${this.currentPage}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          this.loaded = false
          this.totalItems = res.data.meta.total
          return res.data.data
        })
        .catch(() => {
          this.loaded = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Liste des utilisateurs',
              text: 'Une erreur est survenue lors de la recuperation.',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
          return []
        })
    },
    async getAllDocumentsByPartner(item) {
      this.loadedFiles = true
      this.currentUserDocuments = await this.$http
        .get(`/user-kyc-doc/find-by-user/${item}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          this.totalDocuments = res.data.length
          this.loadedFiles = false
          return res.data
        })
        .catch(() => {
          this.loadedFiles = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Liste des documents',
              text: 'Une erreur est survenue lors de la recuperation.',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
          return []
        })
    },
    tryToBlock() {
      this.lockAccount(this.currentUser.id)
    },
    tryToTreat() {
      this.treatKycDocument(this.currentDocumentStorage.id)
    },
    tryToReject() {
      this.$v.motifRejet.$touch()
      if (this.$v.motifRejet.$anyError) return
      this.rejectKycDocument({
        id: this.currentDocumentStorage.id,
        motif: this.motifRejet,
      })
    },
    tryToValid() {
      this.validKycDocument(this.currentDocumentStorage.id)
    },
    tryToValidProfile() {
      this.validProfile(this.currentUser.id)
    },
  },
}
</script>

<style>

</style>
